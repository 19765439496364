<template>
  <div class="search-box">
    <el-form :inline="true" :model="queryForm" class="demo-form-inline">
      <el-form-item label="编码">
        <el-input
            v-model="queryForm.code"
            placeholder=""
            size="small"
        ></el-input>
      </el-form-item>
      <el-form-item label="名称">
        <el-input
            v-model="queryForm.name"
            placeholder=""
            size="small"
        ></el-input>
      </el-form-item>
      <el-form-item>
        <el-button icon="el-icon-search" type="primary" @click="submit" size="small">查询</el-button>
        <el-button icon="el-icon-plus" @click="addInfo" size="small" v-show="hasAddPermision"
        >新增
        </el-button
        >
      </el-form-item>
    </el-form>
  </div>
  <div class="data-box">
    <el-table
        v-loading="loading"
        element-loading-text="加载中..."
        element-loading-spinner="el-icon-loading"
        element-loading-background="rgba(0, 0, 0, 0.8)"
        :data="tableData"
        style="width: 100%"
    >
      <el-table-column prop="code" label="编码"/>
      <el-table-column prop="name" label="名称"/>
      <el-table-column prop="dictionary" label="字典选项" :show-overflow-tooltip="true">
        <template #default="scope">
          <span v-for="(item,index) in JSON.parse(scope.row.dictionary)">{{ item.label }}{{ (index+1)<(JSON.parse(scope.row.dictionary).length)?',':''}}</span>
        </template>
      </el-table-column>
      <el-table-column label="操作" fixed="right" width="180" align="center" v-if="hasDeletePermision||hasEditPermision">
        <template #default="scope">
          <el-button icon="el-icon-edit" size="mini" @click="handleEdit(scope.$index, scope.row)" v-show="hasEditPermision"
          >编辑
          </el-button
          >
          <el-popconfirm
              confirm-button-text="确定"
              cancel-button-text="取消"
              :title="'确定删除数据字典['+scope.row.name+']吗?'"
              @confirm="handleDelete(scope.$index, scope.row)"
          >
            <template #reference>
              <el-button icon="el-icon-delete" size="mini" type="danger" v-show="hasDeletePermision">删除</el-button>
            </template>
          </el-popconfirm>
        </template>
      </el-table-column>
    </el-table>
  </div>
  <div class="page-box">
    <el-pagination
        v-model:currentPage="queryForm.curPage"
        :page-sizes="[15, 50, 100, 200]"
        :page-size="queryForm.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
        background="true"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
    >
    </el-pagination>
  </div>

  <!--数据字典表新增、修改弹窗-->
  <el-dialog
      v-model="dialogVisible"
      :title="dialogTitle"
      width="30%"
      @close="handleClose"
  >
    <el-form
        ref="ruleForm"
        :model="ruleForm"
        :rules="rules"
        label-width="80px"
        class="demo-ruleForm"
    >
      <el-form-item label="名称" prop="name" class="label-color">
        <el-input
            v-model="ruleForm.name"
            placeholder="请输入名称"
            clearable
        ></el-input>
      </el-form-item>
      <el-form-item label="编码" prop="code" class="label-color">
        <el-input
            v-model="ruleForm.code"
            placeholder="请输入编码"
            clearable
            :readonly="codeReadonly"
        ></el-input>
      </el-form-item>
      <!-- 字典集合-->
      <el-divider content-position="left">字典选项</el-divider>
      <div class="dictionary-box">
        <el-row justify="center">
          <el-col :span="6" class="col-title" align="center"><strong>*</strong>排序</el-col>
          <el-col :span="6" class="col-title" align="center"><strong>*</strong>名称</el-col>
          <el-col :span="6" class="col-title" align="center">{{ valTitle }}</el-col>
          <el-col :span="6" class="col-title"></el-col>
        </el-row>
        <el-row v-for="item in ruleForm.dictionaryDatas">
          <el-col :span="6">
            <el-input
                v-model="item.seq"
                placeholder="排序"
                clearable
                size="small"
                type="number"
            ></el-input>
          </el-col>
          <el-col :span="6">
            <el-input
                v-model="item.label"
                placeholder="名称"
                clearable
                size="small"
            ></el-input>
          </el-col>
          <el-col :span="6">
            <el-input
                v-model="item.val"
                :placeholder="valTitle"
                clearable
                size="small"
            ></el-input>
          </el-col>
          <el-col :span="6">
            <el-button icon="el-icon-delete" size="mini" type="text" @click="deleteDicRow(item.id)">删除</el-button>
          </el-col>
        </el-row>
        <el-row>
          <el-button icon="el-icon-plus" size="mini" type="text" @click="addDicRow">添加</el-button>
          <el-button icon="el-icon-circle-close" size="mini" type="text" @click="clearDicRow">清空</el-button>
        </el-row>
      </div>
    </el-form>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="dialogVisible = false">取消</el-button>
        <el-button type="primary" @click="saveInfo" v-if="isSave == true"
        >保存</el-button
        >
        <el-button type="primary" @click="editInfo" v-if="isSave == false"
        >修改</el-button
        >
      </span>
    </template>
  </el-dialog>
</template>

<script>
import dictionaryApi from "@/api/system/dictionary";

export default {
  data() {
    return {
      hasAddPermision: false,// 是否添加权限
      hasEditPermision: false,// 是否修改权限
      hasDeletePermision: false,// 是否删除权限
      queryForm: {
        code: "",
        name: "",
        pageSize: 15,
        curPage: 1,
      },
      loading: false,
      total: 0,
      tableData: [],
      isSave: false,
      ruleForm: {
        code: "",
        name: "",
        dictionary: "",
        dictionaryDatas: [],// 字典集合
      },
      codeReadonly:false,// 编码不能编辑
      rules: {
        code: [
          {
            required: true,
            message: "请输入编码",
            trigger: ["blur", "change"],
          },
        ],
        name: [
          {
            required: true,
            message: "请输入名称",
            trigger: ["blur", "change"],
          },
        ],
      },
      dialogTitle: "",
      dialogVisible: false,
      valTitle:'值',// 值标题
    };
  },
  mounted() {
    this.$nextTick(()=> {
      // 获取设置按钮权限
      this.hasAddPermision = this.$menuUtil.hasMenu('/system/dictionaryManage:add');
      this.hasEditPermision = this.$menuUtil.hasMenu('/system/dictionaryManage:edit');
      this.hasDeletePermision = this.$menuUtil.hasMenu('/system/dictionaryManage:delete');
    })
    this.queryDictionary();
  },
  methods: {
    clearSelect() {
      this.queryDictionary();
    },
    submit() {
      this.queryForm.curPage = 1;
      this.queryDictionary();
    },
    handleSizeChange(val) {
      this.queryForm.pageSize = val;
      this.queryDictionary();
    },
    handleCurrentChange(val) {
      this.queryForm.curPage = val;
      this.queryDictionary();
    },
    queryDictionary() {
      dictionaryApi.queryDictionary(this.queryForm)
          .then((response) => {
            this.tableData = response.data.data.records;
            this.total = response.data.data.total;
          });
    },
    handleEdit(index, row) {
      console.log(index, row);
      this.isSave = false;
      this.dialogTitle = "编辑数据字典表信息";
      this.dialogVisible = true;
      this.ruleForm = Object.assign({},row);
      this.ruleForm.dictionaryDatas = JSON.parse(row.dictionary)
      this.codeReadonly=true;
      if(row.code=='reserveTime'){
        this.valTitle='最大预约人数';
      }else{
        this.valTitle='值';
      }
    },
    addInfo() {
      this.isSave = true;
      this.dialogTitle = "新增数据字典表";
      this.dialogVisible = true;
      // 重置表单
      this.ruleForm = {};
      this.ruleForm.dictionaryDatas=[];
      this.valTitle='值';
    },
    handleDelete(index, row) {
      dictionaryApi.delDictionary(row.id).then((response) => {
        if (response.data.code == 1) {
          this.$notify({
            title: response.data.msg,
            type: "success",
            offset: 40,
            duration: 2000,
          });
          this.tableData.splice(index, 1);
        } else {
          this.$notify({
            title: response.data.msg,
            type: "warning",
            offset: 40,
            duration: 3000,
          });
        }
      });
    },
    saveInfo() {
      this.$refs["ruleForm"].validate((valid) => {
        if (valid) {
          // 判断字典是否为空
          if(!this.checkDictionaryData()){
            return;
          }
          // 设置数据字典
          this.ruleForm.dictionary=JSON.stringify(this.ruleForm.dictionaryDatas);
          // 调用接口保存
          dictionaryApi.addDictionary(this.ruleForm).then((response) => {
            if (response.data.code == 1) {
              this.$notify({
                title: response.data.msg,
                type: "success",
                offset: 40,
                duration: 2000,
              });
              // 关闭窗口
              this.dialogVisible = false;
              // 刷新列表
              this.queryDictionary();
            } else {
              this.$notify({
                title: "保存失败：" + response.data.msg,
                type: "warning",
                offset: 40,
                duration: 2000,
              });

            }
          });
        }
      });
    },
    editInfo() {
      this.$refs["ruleForm"].validate((valid) => {
        if (valid) {
          // 判断字典是否为空
          if(!this.checkDictionaryData()){
            return;
          }
          // 设置数据字典
          this.ruleForm.dictionary=JSON.stringify(this.ruleForm.dictionaryDatas);
          // 调用接口保存
          dictionaryApi.editDictionary(this.ruleForm).then((response) => {
            if (response.data.code == 1) {
              this.$notify({
                title: response.data.msg,
                type: "success",
                offset: 40,
                duration: 2000,
              });
              // 关闭窗口
              this.dialogVisible = false;
              // 重置表单
              // this.$refs['ruleForm'].resetFields();
              // 刷新列表
              this.queryDictionary();
            } else {
              this.$notify({
                title: "修改失败：" + response.data.msg,
                type: "warning",
                offset: 40,
                duration: 2000,
              });

            }
          });
        }
      });
    },
    // 弹窗关闭事件
    handleClose() {
      this.ruleForm = {};
    },
    // 删除字典
    deleteDicRow(id){
      console.log(id)
      // 从数组中删除
      for(let i=0;i<this.ruleForm.dictionaryDatas.length;i++){
        let item=this.ruleForm.dictionaryDatas[i];
        if(item.id==id){
          this.ruleForm.dictionaryDatas.splice(i,1)
          return;
        }
      }
    },
    // 增加字典行
    addDicRow(){
      let item={id:new Date().getTime(),seq:this.ruleForm.dictionaryDatas.length+1};
      this.ruleForm.dictionaryDatas.push(item)
    },
    // 清空选项
    clearDicRow(){
      this.ruleForm.dictionaryDatas=[];
    },
    // 判断字典行
    checkDictionaryData(){
      if(this.ruleForm.dictionaryDatas.length==0){
        this.$notify({
          title: "请添加字典选项",
          type: "warning",
          offset: 40,
          duration: 2000,
        });
        return false;
      }
      // 判断是否存在空值
      for(let i=0;i<this.ruleForm.dictionaryDatas.length;i++){
        let item=this.ruleForm.dictionaryDatas[i];
        if(item.label==undefined||item.label==''||item.seq==undefined||item.seq==''){
          this.$notify({
            title: "排序和名称必填",
            type: "warning",
            offset: 40,
            duration: 2000,
          });
          return false;
        }
      }
      return true;
    }
  },

};
</script>

<style scoped>
.col-title {
  font-weight: bold;
}
.col-title strong{
  color: red;
  padding-right: 3px;
}
.dictionary-box{
  max-height: 300px;
  overflow-y: scroll;
}
.el-row {
  margin-bottom: 10px;
}
.el-col{
  padding-right: 3px;
}
</style>