/**
 * 数据字典表模块接口列表
 */
import request from '@/api/myaxios'; // 导入http中创建的axios实例

const dictionary = {
    // 数据字典表查询
    queryDictionary(data) {
        return request({
            url: '/api/system/dictionary/queryDictionary',
            method: 'post',
            data: data
        });
    },
    // 删除数据字典表
    delDictionary(id) {
        return request({
            url: '/api/system/dictionary/delDictionary/'+id,
            method: 'get'
        });
    },
    // 新增数据字典表
    addDictionary(data) {
        return request({
            url: '/api/system/dictionary/addDictionary',
            method: 'post',
            data: data
        });
    },
    // 编辑数据字典表
    editDictionary(data) {
        return request({
            url: '/api/system/dictionary/editDictionary',
            method: 'post',
            data: data
        });
    },
    // 根据代码查询字典
    queryByCode(code) {
        return request({
            url: '/api/system/dictionary/queryByCode/'+code,
            method: 'get',
        });
    },
    // 查询打印机列表
    queryPrinter() {
        return request({
            url: '/api/system/dictionary/queryPrinter',
            method: 'get',
        });
    }
}

export default dictionary;